import React from "react";
import { Input, Space, Spin, Button } from "antd";
import { EnterOutlined } from "@ant-design/icons";
import BackgroundLogo from "../../Images/backlogo.png";

const { Search } = Input;
const AuthCheck = ({
  searchEmployee,
  surveyTitle,
  loading,
  error,
  surveyBgImage,
  agencyName,
  isAnonymous,
  landingPageContent,
}) => {
  let sectionStyle = {
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundImage: `url(${surveyBgImage})`,
    height: "calc( 100vh - 100px)",
  };
  return (
    <div className="survey-body" style={sectionStyle}>
      {surveyTitle ? (
        <>
          <div className="survey-body-cnt">
            <h1>{surveyTitle}</h1>
            {landingPageContent.split("\n").map((content) => (
              <p>{content}</p>
            ))}
            {isAnonymous ? (
              <div>
                <div
                  className="enter-user-id"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <div
                    tabIndex={0}
                    onKeyDown={(e) => {
                      if (e.code === "Enter") searchEmployee(undefined);
                    }}
                  >
                    <Button
                      style={{ background: "#dd4b4b", borderColor: "#dd4b4b" }}
                      size="large"
                      type="primary"
                      onClick={() => searchEmployee(undefined)}
                      loading={loading}
                    >
                      Start Survey
                    </Button>
                  </div>
                </div>
              </div>
            ) : (
              <div>
                <p style={{ textAlign: "center" }}>
                  Fill in the Employee Id and Start.
                </p>
                <div className="enter-user-id">
                  <Input.Search
                    placeholder="Employee ID"
                    allowClear
                    enterButton="Start Now"
                    size="large"
                    loading={loading}
                    onSearch={searchEmployee}
                  />
                  <span className="enter-user-label">
                    press <b>Enter</b>{" "}
                    <EnterOutlined style={{ color: "#fff" }} />
                  </span>
                </div>
              </div>
            )}

            <br />
            <p
              className="error-text"
              style={{ textAlign: "center", color: "red", fontSize: "18px" }}
            >
              <b>{error}</b>
            </p>
            <p>
              {}
              {isAnonymous
                ? `If you have any issues accessing the survey, please contact `
                : ` If you have any issues validating your employee ID, please contact `}
              <a href="mailto:info@cultureintransit.com">
                info@cultureintransit.com
              </a>
            </p>
          </div>
        </>
      ) : (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <Spin
            size="large"
            className="survey-spinner"
            style={{ marginBottom: "15px" }}
          />
          <p style={{ textAlign: "center" }}>
            Please wait while we prepare your survey ...
          </p>
        </div>
      )}
    </div>
  );
};
export default AuthCheck;
